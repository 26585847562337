import React from "react"
import { Link } from "gatsby"
import image from '../../static/assets/logo.png'

const Logo = (props) => (
  <div className="site-logo">
    <Link to="/" style={{
      display: 'flex',
      backgroundColor: 'white',
      opacity: '90%',
      padding: "0.5rem",
      borderRadius: "0.325rem",
      alignItems: "center",
      color: 'black'}}> 
      <img src={image} style={{height: '2rem', margin: '0.25rem'}}/>
      {props.title}
    </Link>
  </div>
)

export default Logo